import { useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import styled from 'styled-components';

import { Form, FormLayout, TextField, Button } from '@shopify/polaris';

import axios from '../../app/axiosConfig';

import PasswordTextField from '../../common/passwordTextField/PasswordTextField';
import { ROUTING_PATHS } from '../../knowledge/paths';

const ButtonContainer = styled.div`
  text-align: center;
`;

const CreatePasswordForm = () => {
  const [searchParams] = useSearchParams();

  // url params
  // we use setState to declare it once
  const [email] = useState(searchParams.get('e'));
  const [token] = useState(searchParams.get('t'));
  const [userId] = useState(searchParams.get('id'));

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const [responseStatus, setResponseStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const notMatch = (() => {
    if (!passwordRepeat.length) {
      return false;
    }

    if (passwordRepeat === password) {
      return false;
    }

    return true;
  })();

  const handleSubmit = async (
    userId,
    password,
    passwordRepeat,
    token,
    notMatch
  ) => {
    if (notMatch || !passwordRepeat.length) return;

    setLoading(true);
    const response = await axios.post('/users/createPassword', {
      userId,
      password,
      token,
    });
    setResponseStatus(response.status);
    setLoading(false);
  };

  const isQueryParamsValid = email && token && userId;

  if (responseStatus === 201 || !isQueryParamsValid) {
    return <Navigate to={ROUTING_PATHS.INDEX} />;
  }

  return (
    <Form
      onSubmit={() => {
        handleSubmit(userId, password, passwordRepeat, token, notMatch);
      }}
    >
      <FormLayout>
        <TextField
          value={email}
          label="Email"
          type="email"
          autoComplete="email"
          disabled
        />
        <PasswordTextField
          value={password}
          setValue={setPassword}
          errorMessage={''}
        />
        <PasswordTextField
          value={passwordRepeat}
          setValue={setPasswordRepeat}
          label={'Repeat password'}
          errorMessage={notMatch && 'Passwords do not match'}
        />
        <ButtonContainer>
          <Button submit primary disabled={loading}>
            Submit
          </Button>
        </ButtonContainer>

        {/*{status === 'loading' && (
          <Spinner accessibilityLabel="Spinner" size="medium" />
        )}*/}
      </FormLayout>
    </Form>
  );
};

export default CreatePasswordForm;
