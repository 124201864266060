import { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { Form, FormLayout, TextField, Button } from '@shopify/polaris';
import { Navigate } from 'react-router-dom';

import { loginAsync } from './sessionSlice';
import PasswordTextField from '../../common/passwordTextField/PasswordTextField';
import { ROUTING_PATHS } from '../../knowledge/paths';

const ButtonContainer = styled.div`
  text-align: center;
`;

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const { authenticated, status, errorMessage } = useSelector(
    (state) => state.session
  );

  const handleSubmit = () => {
    dispatch(loginAsync({ email, password }));
  };

  if (authenticated) {
    return <Navigate to={ROUTING_PATHS.INDEX} />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormLayout>
        <TextField
          value={email}
          onChange={setEmail}
          label="Email"
          type="email"
          autoComplete="email"
        />
        <PasswordTextField
          value={password}
          setValue={setPassword}
          errorMessage={errorMessage}
        />
        <ButtonContainer>
          <Button submit primary disabled={status === 'loading'}>
            Login
          </Button>
        </ButtonContainer>

        {/*{status === 'loading' && (
          <Spinner accessibilityLabel="Spinner" size="medium" />
        )}*/}
      </FormLayout>
    </Form>
  );
};

export default LoginForm;
