import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getShopDataThunk } from '../shop/shopSlice';
import {
  SYNC_ENTITY,
  SYNC_MESSAGE_CREATORS,
  SYNC_STATUS,
} from '../../knowledge/constants';

import {
  queueSyncReq,
  startSyncReq,
  stopSyncReq,
} from './historicalDataSyncAPI';

import {
  getSyncMessage,
  getSyncPropertiesFromSyncData,
} from './historicalDataSyncHelpers';

const initialState = {
  syncData: {
    [SYNC_ENTITY.PRODUCTS]: {
      syncStatus: SYNC_STATUS.NOT_STARTED,
      syncMessage: SYNC_MESSAGE_CREATORS.NOT_STARTED(),
    },
    [SYNC_ENTITY.ORDERS]: {
      syncStatus: SYNC_STATUS.NOT_STARTED,
      syncMessage: SYNC_MESSAGE_CREATORS.NOT_STARTED(),
    },
    [SYNC_ENTITY.CUSTOMERS]: {
      syncStatus: SYNC_STATUS.NOT_STARTED,
      syncMessage: SYNC_MESSAGE_CREATORS.NOT_STARTED(),
    },
  },
  meta: {
    // TODO unused: consider to remove
    [SYNC_ENTITY.PRODUCTS]: {
      isLoading: false,
    },
    [SYNC_ENTITY.CUSTOMERS]: {
      isLoading: false,
    },
    [SYNC_ENTITY.ORDERS]: {
      isLoading: false,
    },
  },
};

export const startDataSyncThunk = createAsyncThunk(
  'historicalDataSync/start',
  async ({ shopKey, syncEntity }, { getState, dispatch }) => {
    dispatch(setLoading({ syncEntity, bool: true }));

    await dispatch(getShopDataThunk({ shopKey }));

    const state = getState().historicalDataSync;

    // check is some of other entities in progress
    const somethingInProgress = (() => {
      const otherEntities = Object.values(SYNC_ENTITY).filter(
        (val) => val !== syncEntity
      );
      let bool = false;
      otherEntities.forEach((val) => {
        if (state.syncData[val].syncStatus === SYNC_STATUS.IN_PROGRESS) {
          bool = true;
        }
      });
      return bool;
    })();

    if (somethingInProgress) {
      await queueSyncReq({ shopKey, syncEntity }).finally(() => {
        dispatch(setLoading({ syncEntity, bool: false }));
      });
    } else {
      dispatch(
        setSyncStatusAndMessage({
          syncEntity,
          nextStatus: SYNC_STATUS.IN_PROGRESS,
        })
      );
      await startSyncReq({ shopKey, syncEntity }).finally(() => {
        dispatch(setLoading({ syncEntity, bool: false }));
      });
    }

    await dispatch(getShopDataThunk({ shopKey }));
  }
);

export const stopDataSyncThunk = createAsyncThunk(
  'historicalDataSync/start',
  async ({ shopKey, syncEntity }, { dispatch }) => {
    await stopSyncReq({ shopKey, syncEntity });
    await dispatch(getShopDataThunk({ shopKey }));
  }
);

const historicalDataSyncSlice = createSlice({
  name: 'historicalDataSync',
  initialState,
  reducers: {
    setSyncStatusAndMessage: (state, action) => {
      const { syncEntity, nextStatus } = action.payload;
      state.syncData[syncEntity].syncStatus = nextStatus;
      state.syncData[syncEntity].syncMessage = getSyncMessage({
        syncEntity,
        syncProperties: getSyncPropertiesFromSyncData({
          syncEntityData: state.syncData[syncEntity],
        }),
      });
    },
    setSyncMessage: (state, action) => {
      const { syncEntity, nextMessage } = action.payload;
      state.syncData[syncEntity].syncMessage = nextMessage;
    },
    setLoading: (state, action) => {
      const { syncEntity, bool } = action.payload;
      state.meta[syncEntity].isLoading = bool;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getShopDataThunk.fulfilled, (state, action) => {
      const { shopData } = action.payload;

      const nextSyncData = {
        [SYNC_ENTITY.PRODUCTS]: {
          syncStatus: shopData.productsSyncStatus,
          syncDate: shopData.productsSyncDate,
          syncedCount: shopData.syncedVariantsCount,
          totalCount: shopData.totalVariantsCount,
        },
        [SYNC_ENTITY.ORDERS]: {
          syncStatus: shopData.ordersSyncStatus,
          syncDate: shopData.ordersSyncDate,
        },
        [SYNC_ENTITY.CUSTOMERS]: {
          syncStatus: shopData.customersSyncStatus,
          syncDate: shopData.customersSyncDate,
        },
      };

      nextSyncData[SYNC_ENTITY.PRODUCTS].syncMessage = getSyncMessage({
        syncEntity: SYNC_ENTITY.PRODUCTS,
        syncProperties: getSyncPropertiesFromSyncData({
          syncEntityData: nextSyncData[SYNC_ENTITY.PRODUCTS],
        }),
      });

      nextSyncData[SYNC_ENTITY.ORDERS].syncMessage = getSyncMessage({
        syncEntity: SYNC_ENTITY.ORDERS,
        syncProperties: getSyncPropertiesFromSyncData({
          syncEntityData: nextSyncData[SYNC_ENTITY.ORDERS],
        }),
      });

      nextSyncData[SYNC_ENTITY.CUSTOMERS].syncMessage = getSyncMessage({
        syncEntity: SYNC_ENTITY.CUSTOMERS,
        syncProperties: getSyncPropertiesFromSyncData({
          syncEntityData: nextSyncData[SYNC_ENTITY.CUSTOMERS],
        }),
      });

      state.syncData = nextSyncData;
    });
  },
});

export const { setSyncStatusAndMessage, setLoading } =
  historicalDataSyncSlice.actions;

export default historicalDataSyncSlice.reducer;
