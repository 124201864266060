import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from '../features/session/sessionSlice';
import shopsReducer from '../features/shops/shopsSlice';
import shopReducer from '../features/shop/shopSlice';
import accountSettings from '../features/accountSettings/accountSettingsSlice';
import historicalDataSync from '../features/historicalDataSync/historicalDataSyncSlice';

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    shops: shopsReducer,
    shop: shopReducer,
    accountSettings: accountSettings,
    historicalDataSync: historicalDataSync,
  },
});
