import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import {
  Button,
  Card,
  FormLayout,
  InlineError,
  Link,
  Stack,
  TextField,
} from '@shopify/polaris';

import { setIsEditing, setDbIdThunk } from './accountSettingsSlice';

import {
  CommonCardWrapper,
  CommonFormInfoCardWrapper,
  CommonHeading,
  CommonHeadingSecondary,
  CommonSectionWrapper,
} from '../../common/styles/Common.styled';

const variant = 'db';

const DatabaseSettings = () => {
  const dispatch = useDispatch();

  const { isEditing, isLoading } = useSelector(
    (state) => state.accountSettings.db
  );

  const { shopData } = useSelector((state) => state.shop);

  const { shopKey, dbId } = shopData;
  const [fieldValue, setFieldValue] = useState(dbId);
  const [isEmptyValue, setIsEmptyValue] = useState(false);

  useEffect(() => {
    if (!dbId) {
      dispatch(setIsEditing({ variant, bool: true }));
    }
  }, [dbId]);

  useEffect(() => {
    setFieldValue(dbId);
  }, [dbId]);

  const handleEdit = () => {
    dispatch(setIsEditing({ variant, bool: true }));
  };

  const handleSubmit = (fieldValue) => {
    if (isEmpty(fieldValue)) {
      setIsEmptyValue(true);
    } else {
      dispatch(setDbIdThunk({ shopKey, dbId: fieldValue }));
    }
  };

  if (isEditing) {
    return (
      <CommonSectionWrapper>
        <CommonHeading>Database ID</CommonHeading>
        <CommonCardWrapper>
          <Card sectioned>
            <FormLayout>
              <TextField
                label="Database ID"
                value={fieldValue}
                onChange={setFieldValue}
                error={isEmptyValue}
              />
              {isEmptyValue && (
                <div style={{ marginTop: '4px' }}>
                  <InlineError message={'Database ID cannot be empty.'} />
                </div>
              )}
            </FormLayout>
            <div style={{ marginTop: '20px' }}>
              <Button
                loading={isLoading}
                onClick={() => handleSubmit(fieldValue)}
                primary
              >
                Submit
              </Button>
            </div>
          </Card>
        </CommonCardWrapper>
      </CommonSectionWrapper>
    );
  }

  return (
    <CommonSectionWrapper>
      <CommonHeading>Database ID</CommonHeading>
      <CommonCardWrapper>
        <Card sectioned>
          <CommonFormInfoCardWrapper>
            <FormLayout>
              <Stack>
                <Stack.Item>
                  <CommonHeadingSecondary>Database ID:</CommonHeadingSecondary>
                </Stack.Item>
                <Stack.Item>
                  <span style={{ fontSize: '16px' }}>{dbId}</span>
                </Stack.Item>
              </Stack>
            </FormLayout>
            <Link onClick={handleEdit}>Edit</Link>
          </CommonFormInfoCardWrapper>
        </Card>
      </CommonCardWrapper>
    </CommonSectionWrapper>
  );
};

export default DatabaseSettings;
