import axios from 'axios';
import { store } from './store';
import { logout } from '../features/session/sessionSlice';

const instance = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_API,
});

instance.interceptors.request.use(
  async function (config) {
    if (config.url === '/refreshToken') {
      return config;
    }
    const expDate = window.localStorage.getItem('expDate');
    const refreshToken = window.localStorage.getItem('refreshToken');

    if (expDate && expDate < Date.now()) {
      const { data } = await instance
        .get('/refreshToken', {
          params: {
            refreshToken,
          },
        })
        .catch((err) => {
          console.error(err);
          store.dispatch(logout());
        });

      const { accessToken, expDate } = data;

      window.localStorage.setItem('accessToken', accessToken);
      window.localStorage.setItem('expDate', expDate);

      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${window.localStorage.getItem(
      'accessToken'
    )}`;
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
