import { useState, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import SidebarNavigation from './sideBarNavigation/SideBarNavigation';
import AccountSettings from '../../features/accountSettings/AccountSettings';
import AccountSettingsPage from '../accountSettings/AccountSettingsPage';
import DatabaseSyncPage from '../databaseSync/DatabaseSyncPage';
import useGetShop from '../../features/shop/useGetShop';

import { ROUTING_PATHS } from '../../knowledge/paths';

import { ShopContent, ShopContainer, ShopLabel } from './ShopPage.styled';

export const TABS = {
  DATA_EXPORT: 'DATA_EXPORT',
  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
  DATABASE_SYNC: 'DATABASE_SYNC',
};

const ShopPage = () => {
  const [activeTab, setActiveTab] = useState(TABS.ACCOUNT_SETTINGS);
  const { shopKey } = useParams();

  useGetShop(shopKey);

  const content = useMemo(() => {
    switch (activeTab) {
      case TABS.ACCOUNT_SETTINGS:
        return <AccountSettingsPage />;
      case TABS.DATABASE_SYNC:
        return <DatabaseSyncPage />;
      default:
        return <AccountSettings />;
    }
  }, [shopKey, activeTab]);

  if (!shopKey) {
    return <Navigate to={ROUTING_PATHS.INDEX} />;
  }

  return (
    <ShopContainer>
      <SidebarNavigation
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isDisabledTabs={false}
      />
      <ShopContent>
        <ShopLabel>{shopKey}</ShopLabel>
        {content}
      </ShopContent>
    </ShopContainer>
  );
};

export default ShopPage;
