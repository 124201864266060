import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getShopDataThunk } from './shopSlice';

function useGetShop(shopKey) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getShopDataThunk({ shopKey }));
  }, []);
}

export default useGetShop;
