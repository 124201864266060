import React, { useState } from 'react';

import {
  Button,
  Card,
  FormLayout,
  Icon,
  InlineError,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import { CircleAlertMajor } from '@shopify/polaris-icons';

import {
  AccountFormHeadingWrapper,
  CommonHeadingIconWrapper,
  CommonCardWrapper,
  CommonHeading,
  CommonSectionWrapper,
} from '../../common/styles/Common.styled';

const AccountFormCard = ({
  title,
  tooltipMessage,
  name,
  isLoading,
  submitInput,
}) => {
  const [nameValue, setNameValue] = useState(name || '');
  const [passwordValue, setPasswordValue] = useState('');

  const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);

  const isEmptyName = isSubmittedOnce && !nameValue;
  const isEmptyPassword = isSubmittedOnce && !passwordValue;

  const onSubmit = ({ nameValue, passwordValue }) => {
    setIsSubmittedOnce(true);
    if (!nameValue && !passwordValue) return;
    submitInput({ name: nameValue, password: passwordValue });
  };

  return (
    <CommonSectionWrapper>
      <Tooltip content={tooltipMessage}>
        <AccountFormHeadingWrapper>
          <CommonHeading>{title}</CommonHeading>
          <CommonHeadingIconWrapper>
            <Icon source={CircleAlertMajor} color="warning" />
          </CommonHeadingIconWrapper>
        </AccountFormHeadingWrapper>
      </Tooltip>
      <CommonCardWrapper>
        <Card sectioned>
          <FormLayout>
            <TextField
              label="Name"
              value={nameValue}
              onChange={setNameValue}
              error={isEmptyName}
              autoComplete="off"
            />

            {isEmptyName && (
              <div style={{ marginTop: '4px' }}>
                <InlineError message={'Name cannot be empty.'} />
              </div>
            )}

            <TextField
              type="Password"
              label="Password"
              value={passwordValue}
              onChange={setPasswordValue}
              error={isEmptyPassword}
              autoComplete="off"
            />

            {isEmptyPassword && (
              <div style={{ marginTop: '4px' }}>
                <InlineError message={'Password cannot be empty.'} />
              </div>
            )}
          </FormLayout>

          <div style={{ marginTop: '20px' }}>
            <Button
              loading={isLoading}
              onClick={() => onSubmit({ nameValue, passwordValue })}
              primary
            >
              Submit
            </Button>
          </div>
        </Card>
      </CommonCardWrapper>
    </CommonSectionWrapper>
  );
};

export default AccountFormCard;
