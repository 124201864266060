import { Navigation } from '@shopify/polaris';
import { HomeMinor } from '@shopify/polaris-icons';

const navigation = (
  <Navigation location="">
    <Navigation.Section
      items={[
        {
          url: '/',
          label: 'Stores List',
          icon: HomeMinor,
        },
      ]}
      fill={true}
    />
  </Navigation>
);

export default navigation;
