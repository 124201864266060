export const SYNC_STATUS = {
  NOT_STARTED: 'notStarted',
  IN_PROGRESS: 'inProgress',
  COMPLETED: 'completed',
  REJECTED: 'rejected',
  STOPPED: 'stopped',
  STOPPING: 'stopping',
  IN_QUEUE: 'in_queue',
  FAILED: 'failed',
};

// warning: changes must be adjusted in HistoricalDataSync.js
export const SYNC_ENTITY = {
  PRODUCTS: 'products',
  ORDERS: 'orders',
  CUSTOMERS: 'customers',
};

export const SYNC_ENTITY_NAME_MAP = {
  products: 'Items',
  orders: 'Interactions',
  customers: 'Users',
};

export const SYNC_MESSAGE_CREATORS = {
  NOT_STARTED: () => 'Out of sync',
  PRODUCTS_IN_PROGRESS: (ready, total) => {
    const out = ready ? `${ready} out of ${total || ''}` : '';
    return `Synchronizing Items... ${out}`;
  },
  ORDERS_IN_PROGRESS: (ready) => {
    // const out = ready ? `${ready} out of ${total || ''}` : '';
    return `Synchronizing Interactions... ${ready}`;
  },
  CUSTOMERS_IN_PROGRESS: (ready, total) => {
    const out = ready ? `${ready} out of ${total || ''}` : '';
    return `Synchronizing Customers... ${out}`;
  },
  COMPLETED: (date) => `Last synced at ${date}`,
  REJECTED: () => 'Rejected', // TODO remove if not needed
  STOPPED: (date) => `Last synced at ${date}`,
  STOPPING: (date) => `Last synced at ${date}`,
  IN_QUEUE: () => 'The synchronisation is in the queue.',
};
