import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getShopReq } from './shopAPI';
import { setDbIdThunk } from '../accountSettings/accountSettingsSlice';
import { shopIdToShopKey } from '../../utils/clipShopId';

const initialState = {
  shopData: null,
  lastGetTime: null,
  status: 'idle',
};

export const getShopDataThunk = createAsyncThunk(
  'shop/get',
  async ({ shopKey }) => {
    const { data } = await getShopReq(shopKey);
    return {
      shopData: data,
    };

    // The value we return becomes the `fulfilled` action payload
  }
);

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getShopDataThunk.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(getShopDataThunk.fulfilled, (state, action) => {
        state.status = 'idle';
        state.lastGetTime = Date.now();
        const shopData = action.payload.shopData;
        state.shopData = {
          ...action.payload.shopData,
          shopKey: shopIdToShopKey(shopData.id),
        };
      })

      .addCase(setDbIdThunk.fulfilled, (state, { payload }) => {
        state.shopData.dbId = payload.dbId;
      });
  },
});

export default shopSlice.reducer;
