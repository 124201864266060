import { useNavigate } from 'react-router-dom';

import { TextStyle } from '@shopify/polaris';
import { ROUTING_PATHS } from '../../knowledge/paths';

import { LogoSuffixContainer } from './LogoSuffix.styled';

const LogoSuffix = () => {
  const navigate = useNavigate();

  return (
    <LogoSuffixContainer
      onClick={() => {
        navigate(ROUTING_PATHS.INDEX);
      }}
    >
      <TextStyle variation={'strong'}>Admin Dashboard</TextStyle>
    </LogoSuffixContainer>
  );
};

export default LogoSuffix;
