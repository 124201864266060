import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTING_PATHS } from '../../knowledge/paths';
import TheFrame from '../../app/TheFrame';

const PublicLayout = () => {
  const authenticated = useSelector((state) => state.session.authenticated);
  if (authenticated) {
    return <Navigate to={ROUTING_PATHS.INDEX} />;
  }

  return (
    <>
      <TheFrame isAuthenticated={false}>
        <Outlet />
      </TheFrame>
    </>
  );
};

export default PublicLayout;
