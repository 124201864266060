import { Page, Card } from '@shopify/polaris';

import ShopsList from '../../features/shops/ShopsList';
import ShopsTabs from './ShopsTabs';

import { Title, Container } from './ShopsListPage.styled';

const ShopsListPage = () => {
  return (
    <Container>
      <Page>
        <Title>Shops List</Title>
        <Card>
          <ShopsTabs />
          <ShopsList />
        </Card>
      </Page>
    </Container>
  );
};

export default ShopsListPage;
