import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loginReq } from './sessionAPI';

const initialState = {
  authenticated: !!window.localStorage.getItem('accessToken'),
  status: 'idle',
  errorMessage: '',
  email: window.localStorage.getItem('email'),
};

export const loginAsync = createAsyncThunk(
  'session/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await loginReq({
        email,
        password,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.status);
    }

    // The value we return becomes the `fulfilled` action payload
  }
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    logout: (state) => {
      window.localStorage.setItem('accessToken', '');
      window.localStorage.setItem('refreshToken', '');
      window.localStorage.setItem('expDate', '');
      window.localStorage.setItem('email', '');
      state = initialState;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = 'loading';
        state.errorMessage = '';
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const { accessToken, refreshToken, email, expDate } = action.payload;

        window.localStorage.setItem('accessToken', accessToken);
        window.localStorage.setItem('refreshToken', refreshToken);
        window.localStorage.setItem('expDate', expDate);
        window.localStorage.setItem('email', email);

        state.authenticated = true;
        state.errorMessage = '';

        state.email = email;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.authenticated = false;
        if (action.payload === 401) {
          state.errorMessage = 'Email or password is invalid';
        }
      });
  },
});

export const { logout } = sessionSlice.actions;

export default sessionSlice.reducer;
