import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getShopsReq } from './shopsAPI';
import { sessionSlice } from '../session/sessionSlice';

const initialState = {
  items: [],
  status: 'idle',
};

export const getShopsAsync = createAsyncThunk('shops/get', async () => {
  const response = await getShopsReq();
  return response.data;

  // The value we return becomes the `fulfilled` action payload
});

export const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getShopsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getShopsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.items = action.payload.items;
      });
  },
});

export const { selectShop } = sessionSlice.actions;

export default shopsSlice.reducer;
