import React from 'react';

import { StyledNavigationWrap } from './SideBarNavigation.styled';
import { Navigation } from '@shopify/polaris';
import { TABS } from '../ShopPage';

import AccountsSettingsIcon from '../../../resources/icons/AccountsSettingsIcon';
import DatabaseSettingsIcon from '../../../resources/icons/DatabaseSettingsIcon';

export default function SidebarNavigation({ activeTab, setActiveTab }) {
  const handleNavClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <StyledNavigationWrap>
      <Navigation location="/">
        <Navigation.Section
          title="Crossing Minds App"
          items={[
            {
              label: (
                <div
                  className={`
                    ${activeTab === TABS.ACCOUNT_SETTINGS ? 'active' : ''}
                    navigation-label
                  `}
                  key={TABS.ACCOUNT_SETTINGS}
                >
                  <div className="icon-wrap">
                    <AccountsSettingsIcon />
                  </div>
                  <div className="text-wrap">
                    Crossing Minds Account Settings
                  </div>
                </div>
              ),
              onClick: () => handleNavClick(TABS.ACCOUNT_SETTINGS),
            },
            {
              label: (
                <div
                  className={`
                    ${activeTab === TABS.DATABASE_SYNC ? 'active' : ''}
                    navigation-label
                  `}
                  key={TABS.DATABASE_SYNC}
                >
                  <div className="icon-wrap">
                    <DatabaseSettingsIcon />
                  </div>
                  <div className="text-wrap">Database Sync</div>
                </div>
              ),
              onClick: () => handleNavClick(TABS.DATABASE_SYNC),
            },
          ]}
        />
      </Navigation>
    </StyledNavigationWrap>
  );
}
