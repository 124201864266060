import React from 'react';

import { Card, FormLayout, Link, Stack } from '@shopify/polaris';

import {
  CommonCardWrapper, CommonFormInfoCardWrapper,
  CommonHeading, CommonHeadingSecondary,
  CommonSectionWrapper
} from '../../common/styles/Common.styled';

const AccountInfoCard = ({ title, name, handleEdit }) => {
  return (
    <CommonSectionWrapper>
      <CommonHeading>{title}</CommonHeading>
      <CommonCardWrapper>
        <Card sectioned>
          <CommonFormInfoCardWrapper>
            <FormLayout>
              <Stack>
                <Stack.Item>
                  <CommonHeadingSecondary>Name:</CommonHeadingSecondary>
                </Stack.Item>
                <Stack.Item>
                  <span style={{ fontSize: '16px' }}>{name}</span>
                </Stack.Item>
              </Stack>
              <Stack>
                <Stack.Item>
                  <CommonHeadingSecondary>Password:</CommonHeadingSecondary>
                </Stack.Item>
                <Stack.Item>
                  <span style={{ fontSize: '16px' }}>********</span>
                </Stack.Item>
              </Stack>
            </FormLayout>
            <Link onClick={handleEdit}>Edit</Link>
          </CommonFormInfoCardWrapper>
        </Card>
      </CommonCardWrapper>
    </CommonSectionWrapper>
  )
}


export default AccountInfoCard;