import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  startDataSyncThunk,
  stopDataSyncThunk,
} from './historicalDataSyncSlice';
import DataSyncSection from '../../components/dataSync/DataSyncSection';
import { SYNC_ENTITY } from '../../knowledge/constants';
import useInterval from '../../hooks/useInterval';
import { getShopDataThunk } from '../shop/shopSlice';

const HistoricalDataSync = () => {
  const dispatch = useDispatch();

  const shopKey = useSelector((state) => state.shop.shopData.shopKey);

  const products = useSelector(
    (state) => state.historicalDataSync.syncData[SYNC_ENTITY.PRODUCTS]
  );
  const orders = useSelector(
    (state) => state.historicalDataSync.syncData[SYNC_ENTITY.ORDERS]
  );
  const customers = useSelector(
    (state) => state.historicalDataSync.syncData[SYNC_ENTITY.CUSTOMERS]
  );

  const startSyncHandler = useCallback(
    (syncEntity) => {
      dispatch(startDataSyncThunk({ shopKey, syncEntity }));
    },
    [dispatch, shopKey]
  );

  const stopSyncHandler = useCallback(
    (syncEntity) => {
      dispatch(stopDataSyncThunk({ shopKey, syncEntity }));
    },
    [dispatch, shopKey]
  );

  useEffect(() => {
    dispatch(getShopDataThunk({ shopKey }));
  }, [dispatch, shopKey]);
  useInterval(() => {
    dispatch(getShopDataThunk({ shopKey }));
  }, 10000);

  return (
    <>
      <DataSyncSection
        syncEntity={SYNC_ENTITY.PRODUCTS}
        syncMessage={products.syncMessage}
        startSync={startSyncHandler}
        stopSync={stopSyncHandler}
        syncStatus={products.syncStatus}
      />

      <DataSyncSection
        syncEntity={SYNC_ENTITY.CUSTOMERS}
        syncMessage={customers.syncMessage}
        startSync={startSyncHandler}
        stopSync={stopSyncHandler}
        syncStatus={customers.syncStatus}
      />

      <DataSyncSection
        syncEntity={SYNC_ENTITY.ORDERS}
        syncMessage={orders.syncMessage}
        startSync={startSyncHandler}
        stopSync={stopSyncHandler}
        syncStatus={orders.syncStatus}
      />
    </>
  );
};

export default HistoricalDataSync;
