import axios from '../../app/axiosConfig';

export const startSyncReq = ({ shopKey, syncEntity }) => {
  return axios.post(`/shops/${shopKey}/sync/${syncEntity}/start`);
};

export const queueSyncReq = ({ shopKey, syncEntity }) => {
  return axios.post(`/shops/${shopKey}/sync/${syncEntity}/queue`);
};

export const stopSyncReq = ({ shopKey, syncEntity }) => {
  return axios.post(`/shops/${shopKey}/sync/${syncEntity}/stop`);
};
