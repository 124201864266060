import { Page } from '@shopify/polaris';

import {
  CommonPageHeaderSpanWrapper,
  CommonPageHeaderWrapper
} from "../../common/styles/Common.styled";
import AccountSettings from "../../features/accountSettings/AccountSettings";

const AccountSettingsPage = () => {
  return (
    <Page>
      <CommonPageHeaderWrapper>
        <CommonPageHeaderSpanWrapper>
          API Settings
        </CommonPageHeaderSpanWrapper>
      </CommonPageHeaderWrapper>

      <AccountSettings />
    </Page>
  );
};

export default AccountSettingsPage;
