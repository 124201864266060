import { useEffect, useState, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { SearchMinor } from '@shopify/polaris-icons';
import {
  ResourceList,
  ResourceItem,
  Button,
  Card,
  Pagination,
  TextField,
  Icon,
} from '@shopify/polaris';

import { getShopsAsync } from './shopsSlice';
import debounce from '../../utils/debounce';
import { shopIdToShopKey } from '../../utils/clipShopId';

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ShopName = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

const Count = styled.p`
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  padding: 0 20px 20px 20px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

// SETTINGS
const pageLimit = 10;

const ShopsList = () => {
  const dispatch = useDispatch();

  const { items } = useSelector((state) => state.shops);

  const [page, setPage] = useState(0);

  const [selectedShopId, setSelectedShopId] = useState('');

  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  const [paginatedItems, setPaginatedItems] = useState(
    items.slice(0, pageLimit)
  );

  const debouncedSetFilteredItems = useMemo(() => {
    return debounce(setFilteredItems, 400);
  }, []);

  useEffect(() => {
    setPaginatedItems(
      filteredItems.slice(pageLimit * page, pageLimit * page + pageLimit)
    );
  }, [filteredItems, page, items]);

  useEffect(() => {
    setPage(0);
    const filteredItems = items.filter((item) =>
      item?.shop.toLowerCase().includes(String(searchValue).toLowerCase())
    );
    debouncedSetFilteredItems(filteredItems);
  }, [items, searchValue]);

  useEffect(() => {
    dispatch(getShopsAsync());
  }, []);

  if (selectedShopId) {
    return <Navigate to={`/shops/${shopIdToShopKey(selectedShopId)}`} />;
  }

  const pagination = items / pageLimit >= 1 && (
    <Pagination
      hasPrevious={page > 0}
      onPrevious={() => {
        setPage((prevState) => prevState - 1);
      }}
      hasNext={page + 1 < items.length / pageLimit}
      onNext={() => {
        setPage((prevState) => prevState + 1);
      }}
    />
  );

  return (
    <>
      <Card.Section>
        <TextField
          autoComplete=""
          label=""
          prefix={<Icon source={SearchMinor} />}
          placeholder={'Search'}
          value={searchValue}
          onChange={setSearchValue}
        />
      </Card.Section>
      <Count>Showing {paginatedItems.length} items</Count>
      <ResourceList
        items={paginatedItems}
        renderItem={({ id, shop }) => {
          return (
            <ResourceItem id={id}>
              <Item>
                <ShopName>{shop}</ShopName>
                <Button
                  primary
                  onClick={() => {
                    setSelectedShopId(id);
                  }}
                >
                  Open
                </Button>
              </Item>
            </ResourceItem>
          );
        }}
      />
      <PaginationContainer>{pagination}</PaginationContainer>
    </>
  );
};

export default ShopsList;
