import { Frame } from '@shopify/polaris';

import logoSvg from '../resources/icons/Logo.svg';

import topBar from '../components/topBar';
import navigation from '../components/navigation';
import { ROUTING_PATHS } from '../knowledge/paths';

const TheFrame = ({ children, isAuthenticated }) => {
  const logo = {
    width: 24,
    topBarSource: logoSvg,
    url: ROUTING_PATHS.INDEX,
    accessibilityLabel: 'Crossing Minds Admin Dashboard',
  };

  if (isAuthenticated) {
    return (
      <Frame topBar={topBar} navigation={navigation} logo={logo}>
        {children}
      </Frame>
    );
  }

  return (
    <Frame logo={logo} topBar={topBar}>
      {children}
    </Frame>
  );
};

export default TheFrame;
