import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTING_PATHS } from '../../knowledge/paths';
import TheFrame from '../../app/TheFrame';

export const ProtectedLayout = () => {
  const authenticated = useSelector((state) => state.session.authenticated);
  if (!authenticated) {
    return <Navigate to={ROUTING_PATHS.LOGIN} />;
  }
  return (
    <>
      <TheFrame isAuthenticated={true}>
        <Outlet />
      </TheFrame>
    </>
  );
};

export default ProtectedLayout;
