import {
  SYNC_ENTITY,
  SYNC_MESSAGE_CREATORS,
  SYNC_STATUS,
} from '../../knowledge/constants';
import { syncDateFormat } from '../../utils/helpers';

export const getSyncPropertiesFromSyncData = ({ syncEntityData }) => {
  return {
    totalCount: syncEntityData.totalCount,
    syncedCount: syncEntityData.syncedCount,
    syncStatus: syncEntityData.syncStatus,
    syncDate: syncEntityData.syncDate,
  };
};

export const getSyncMessage = ({ syncEntity, syncProperties }) => {
  const { totalCount, syncedCount, syncStatus, syncDate } = syncProperties;

  switch (syncStatus) {
    case SYNC_STATUS.NOT_STARTED:
      return SYNC_MESSAGE_CREATORS.NOT_STARTED();
    case SYNC_STATUS.IN_PROGRESS:
      return (() => {
        switch (syncEntity) {
          case SYNC_ENTITY.PRODUCTS:
            return SYNC_MESSAGE_CREATORS.PRODUCTS_IN_PROGRESS(
              syncedCount,
              totalCount
            );
          case SYNC_ENTITY.CUSTOMERS:
            return SYNC_MESSAGE_CREATORS.CUSTOMERS_IN_PROGRESS(
              syncedCount,
              totalCount
            );
          case SYNC_ENTITY.ORDERS:
            return SYNC_MESSAGE_CREATORS.ORDERS_IN_PROGRESS(
              syncedCount,
              totalCount
            );
          default:
            throw new Error(`Unknown syncEntity:${syncEntity}`);
        }
      })();
    case SYNC_STATUS.STOPPING:
      return SYNC_MESSAGE_CREATORS.STOPPING(syncDateFormat(syncDate));
    case SYNC_STATUS.STOPPED:
      return SYNC_MESSAGE_CREATORS.STOPPED(syncDateFormat(syncDate));
    case SYNC_STATUS.COMPLETED:
      return SYNC_MESSAGE_CREATORS.COMPLETED(syncDateFormat(syncDate));
    case SYNC_STATUS.IN_QUEUE:
      return SYNC_MESSAGE_CREATORS.IN_QUEUE();
    default:
      return SYNC_MESSAGE_CREATORS.NOT_STARTED();
  }
};
