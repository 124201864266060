import styled from 'styled-components';

const DatabaseHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DatabaseSyncCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DatabaseSyncCardMessage = styled.p`
  margin-left: 15px;
  font-size: 16px;
  color: #5c5f62;
  font-weight: 500;
  max-width: 360px;
`;
const DatabaseSyncCardSyncDateMessage = styled.p`
  margin-left: 15px;
  font-size: 16px;
  color: #347c84;
  font-weight: 500;
  max-width: 360px;
`;

const DatabaseSyncHeaderMessage = styled.p`
  color: #5c5f62;
  margin-bottom: 15px;
`;

export {
  DatabaseHeadingWrapper,
  DatabaseSyncCardWrapper,
  DatabaseSyncCardMessage,
  DatabaseSyncHeaderMessage,
  DatabaseSyncCardSyncDateMessage,
};
