import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  setAccountThunk,
  setIsEditing,
  SETTINGS_VARIANTS,
} from './accountSettingsSlice';

import AccountFormCard from '../../components/accountSettings/AccountFormCard';
import AccountInfoCard from '../../components/accountSettings/AccountInfoCard';

const BackendAccount = () => {
  const dispatch = useDispatch();

  const { shopData } = useSelector((state) => state.shop);

  const { name, isEditing, isLoading } = useSelector(
    (state) => state.accountSettings[SETTINGS_VARIANTS.BACKEND]
  );

  const handleEdit = useCallback(() => {
    dispatch(setIsEditing({ variant: SETTINGS_VARIANTS.BACKEND, bool: true }));
  }, []);

  const handleSubmit = useCallback(
    ({ name, password }) => {
      dispatch(
        setAccountThunk({
          shopKey: shopData.shopKey,
          name,
          password,
          accountType: SETTINGS_VARIANTS.BACKEND,
        })
      );
    },
    [shopData]
  );

  if (isEditing) {
    return (
      <AccountFormCard
        title="Crossing Minds Service Account Backend"
        tooltipMessage="This account is required to start syncing your store activities and databases."
        name={name}
        isLoading={isLoading}
        submitInput={handleSubmit}
      />
    );
  }

  return (
    <AccountInfoCard
      title="Crossing Minds Service Account Backend"
      name={name}
      handleEdit={handleEdit}
    />
  );
};

export default BackendAccount;
