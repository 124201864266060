import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TopBar } from '@shopify/polaris';
import { ExitMajor } from '@shopify/polaris-icons';

import { logout } from './sessionSlice';
import { ROUTING_PATHS } from '../../knowledge/paths';

const UserTopBarElement = () => {
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.session);

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  if (!email) {
    return null;
  }

  return (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: 'Log out',
              icon: ExitMajor,
              onAction: () => {
                dispatch(logout());
              },
              url: ROUTING_PATHS.INDEX,
            },
          ],
        },
      ]}
      name={email}
      initials={email[0].toUpperCase()}
      open={isUserMenuOpen}
      onToggle={() => setIsUserMenuOpen(!isUserMenuOpen)}
    />
  );
};

export default UserTopBarElement;
