import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  SETTINGS_VARIANTS,
  setIsEditing,
  setAccountThunk,
} from './accountSettingsSlice';

import AccountFormCard from '../../components/accountSettings/AccountFormCard';
import AccountInfoCard from '../../components/accountSettings/AccountInfoCard';

const FrontendAccount = () => {
  const dispatch = useDispatch();

  const shopData = useSelector((state) => state.shop.shopData);

  const { name, isEditing, isLoading } = useSelector(
    (state) => state.accountSettings[SETTINGS_VARIANTS.FRONTEND]
  );

  const handleEdit = useCallback(() => {
    dispatch(setIsEditing({ variant: SETTINGS_VARIANTS.FRONTEND, bool: true }));
  }, []);

  const handleSubmit = useCallback(
    ({ name, password }) => {
      dispatch(
        setAccountThunk({
          shopKey: shopData.shopKey,
          name,
          password,
          accountType: SETTINGS_VARIANTS.FRONTEND,
        })
      );
    },
    [shopData]
  );

  if (isEditing) {
    return (
      <AccountFormCard
        title="Crossing Minds Service Account Frontend"
        tooltipMessage="This account is required to start deploying recommendations in your store."
        name={name}
        isLoading={isLoading}
        submitInput={handleSubmit}
      />
    );
  }

  return (
    <AccountInfoCard
      title="Crossing Minds Service Account Frontend"
      name={name}
      handleEdit={handleEdit}
    />
  );
};

export default FrontendAccount;
