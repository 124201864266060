import styled from 'styled-components';

const CommonSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const CardWrapper = styled.div`
  margin-bottom: 30px;
  margin-left: 185px;
`;

const CommonPageHeaderWrapper = styled.div`
  display: flex;
  margin: 30px 10px 40px 0px;
`;

const CommonPageHeaderSpanWrapper = styled.div`
  font-size: 20px;
  margin-right: 10px;
`;

const CommonHeadingIconWrapper = styled.div`
  position: absolute;
  margin: 0 65px 0 0;
`;

const CommonHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
  width: 170px;
  padding-right: 10px;
`;

const CommonHeadingSecondary = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding-right: 10px;
`;

const CommonCardWrapper = styled.div`
  margin: 0 0 10px;
  width: calc(100% - 185px);
`;

const CommonBannerWrapper = styled.div`
  margin: 30px 0;
`;

const SnippetBannerWrapper = styled.div`
  .interactionSnipHolder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 220px;
    margin-right: 10px;
  }
`;

const SettingsBannerSpanWrapper = styled.span`
  font-size: 20px;
  margin-bottom: 20px;
`;

const CommonFormInfoCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const AccountFormHeadingWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;
`;

export {
  CommonSectionWrapper,
  CommonPageHeaderWrapper,
  CommonPageHeaderSpanWrapper,
  CommonHeadingIconWrapper,
  CommonHeading,
  CommonHeadingSecondary,
  CommonCardWrapper,
  CardWrapper,
  CommonBannerWrapper,
  SettingsBannerSpanWrapper,
  CommonFormInfoCardWrapper,
  AccountFormHeadingWrapper,
  SnippetBannerWrapper,
};
