import styled from 'styled-components';

const StyledNavigationWrap = styled.div`
  .Polaris-Navigation__SectionHeading {
    margin-bottom: 10px;
  }

  .Polaris-Navigation__Item {
    padding-left: 5px;
    margin-bottom: 5px;

    &:hover {
      background-color: #f1f8f5;
    }
  }

  .text-wrap {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgb(43 43 43);
    max-width: 85%;
  }

  .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 30px;
    min-width: 33px;
    margin-top: 5px;

    svg {
      color: rgb(89 91 92);
    }
  }

  .active {
    background-color: #f1f8f5;

    .text-wrap {
      color: #347c84;
    }

    .icon-wrap {
      svg {
        color: #347c84;
      }
    }
  }

  .disabled {
    .text-wrap {
      color: #8c9196;
    }

    .icon-wrap {
      svg {
        color: #8c9196;
      }
    }
  }

  .navigation-label {
    display: flex;
  }
`;

export { StyledNavigationWrap };
