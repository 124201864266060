import React from 'react';
import { Button, Card, Spinner } from '@shopify/polaris';

import { SYNC_ENTITY_NAME_MAP, SYNC_STATUS } from '../../knowledge/constants';

import {
  CommonCardWrapper,
  CommonHeading,
  CommonSectionWrapper,
} from '../../common/styles/Common.styled';
import {
  DatabaseSyncCardMessage,
  DatabaseSyncCardSyncDateMessage,
  DatabaseSyncCardWrapper,
} from './DataSyncSection.styled';

const DataSyncSection = ({
  startSync,
  stopSync,
  syncEntity,
  syncStatus,
  syncMessage,
}) => {
  const info = (() => {
    switch (syncStatus) {
      case SYNC_STATUS.IN_PROGRESS:
        return (
          <div className="flex-set">
            <Spinner accessibilityLabel="Small spinner example" size="small" />
            <DatabaseSyncCardMessage>
              {syncMessage || ''}
            </DatabaseSyncCardMessage>
          </div>
        );
      case SYNC_STATUS.COMPLETED:
      case SYNC_STATUS.STOPPED:
      case SYNC_STATUS.STOPPING:
        return (
          <DatabaseSyncCardSyncDateMessage>
            {syncMessage}
          </DatabaseSyncCardSyncDateMessage>
        );
      default:
        return <DatabaseSyncCardMessage>{syncMessage}</DatabaseSyncCardMessage>;
    }
  })();

  const button = (() => {
    switch (syncStatus) {
      case SYNC_STATUS.IN_PROGRESS:
        return (
          <div style={{ color: '#bf0711' }}>
            <Button
              onClick={() => stopSync(syncEntity)}
              monochrome
              outline
              fullWidth
            >
              {' '}
              Stop
            </Button>
          </div>
        );
      default:
        return (
          <div>
            <Button
              onClick={() => startSync(syncEntity)}
              primary
              fullWidth
              disabled={syncStatus === SYNC_STATUS.IN_QUEUE}
            >
              Start Sync
            </Button>
          </div>
        );
    }
  })();

  return (
    <CommonSectionWrapper>
      <CommonHeading>{SYNC_ENTITY_NAME_MAP[syncEntity]}</CommonHeading>
      <CommonCardWrapper>
        <Card sectioned>
          <DatabaseSyncCardWrapper>
            {info}
            {button}
          </DatabaseSyncCardWrapper>
        </Card>
      </CommonCardWrapper>
    </CommonSectionWrapper>
  );
};

export default DataSyncSection;
