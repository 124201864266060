import { Page } from '@shopify/polaris';

import {
  CommonPageHeaderSpanWrapper,
  CommonPageHeaderWrapper,
} from '../../common/styles/Common.styled';

import HistoricalDataSync from '../../features/historicalDataSync/HistoricalDataSync';

const DatabaseSyncPage = () => {
  return (
    <Page>
      <CommonPageHeaderWrapper>
        <CommonPageHeaderSpanWrapper>
          Historical Data Synchronization
        </CommonPageHeaderSpanWrapper>
      </CommonPageHeaderWrapper>

      <HistoricalDataSync />
    </Page>
  );
};

export default DatabaseSyncPage;
