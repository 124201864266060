import { useState } from 'react';

import { Tabs } from '@shopify/polaris';

const ShopsTabs = () => {
  const [selected, setSelected] = useState(0);

  const tabs = [
    {
      id: 'all-shops-1',
      content: 'All',
      accessibilityLabel: 'All shops',
      panelID: 'all-shops-content-1',
    },
    /*{
      id: 'active-shops-1',
      content: 'Active',
      panelID: 'active-shops-content-1',
    },
    {
      id: 'archived-shops-1',
      content: 'Archived',
      panelID: 'archived-shops-content-1',
    },*/
  ];

  return <Tabs tabs={tabs} selected={selected} onSelect={setSelected} />;
};

export default ShopsTabs;
