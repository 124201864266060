import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';

import '@shopify/polaris/build/esm/styles.css';
import './flex.css';

import Login from '../pages/Login';
import CreatePasswordPage from '../pages/createPassword/CreatePasswordPage';
import ShopsListPage from '../pages/shopsList/ShopsListPage';

import ProtectedLayout from '../common/layouts/ProtectedLayout';
import ShopPage from '../pages/shop/ShopPage';

import { ROUTING_PATHS } from '../knowledge/paths';
import PublicLayout from '../common/layouts/PublicLayout';

function App() {
  return (
    <AppProvider i18n={translations}>
      <BrowserRouter>
        <Routes>
          <Route path={ROUTING_PATHS.INDEX} element={<ProtectedLayout />}>
            <Route index element={<ShopsListPage />} />
            <Route path={ROUTING_PATHS.SHOP} element={<ShopPage />} />
          </Route>
          <Route element={<PublicLayout />}>
            <Route path={ROUTING_PATHS.LOGIN} element={<Login />} />
            <Route
              path={ROUTING_PATHS.CREATE_PASS}
              element={<CreatePasswordPage />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
