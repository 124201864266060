import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const Clickable = styled.div`
  position: absolute;
  padding: 5px;
  right: 9px;
  top: 27px;
  z-index: 50;
  cursor: pointer;
`;

const EyeIcon = styled.div`
  width: 20px;
  height: 20px;
`;

export { Container, Clickable, EyeIcon };
