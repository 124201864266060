import styled from 'styled-components';

export const Title = styled.p`
  font-weight: 590;
  font-size: 24px;
  line-height: 29px;
  color: #202223;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  height: 94vh;
  overflow-y: scroll;
`;
