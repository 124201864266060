import styled from 'styled-components';

export const ShopContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const ShopContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  .Polaris-Page {
    width: 100%;
  }
`;

export const ShopLabel = styled.div`
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  color: #202223;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 14px;
`;
