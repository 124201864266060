import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAccountsThunk } from './accountSettingsSlice';

import DatabaseSettings from './DatabaseSettings';
import FrontendAccount from './FrontendAccount';
import BackendAccount from './BackendAccount';

const AccountSettings = () => {
  const dispatch = useDispatch();

  const { shopData, status } = useSelector((state) => state.shop);

  useEffect(() => {
    if (!shopData) return;
    dispatch(
      getAccountsThunk({
        shopKey: shopData.shopKey,
      })
    );
  }, [shopData]);

  return (
    <>
      {shopData && status === 'idle' && (
        <div>
          <DatabaseSettings />
          <FrontendAccount />
          <BackendAccount />
        </div>
      )}
    </>
  );
};

export default AccountSettings;
