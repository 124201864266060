import { Card } from '@shopify/polaris';
import styled from 'styled-components';

import LoginForm from '../features/session/LoginForm';

const TitleContainer = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-align: center;
`;

const SubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #6d7175;
`;

const InputContainer = styled.div`
  width: 60%;
  margin: 0 auto;
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CardContent = styled.div`
  width: 914px;
`;

const Login = () => {
  return (
    <PageContainer>
      <Card sectioned>
        <CardContent>
          <TitleContainer>
            <Title>Login</Title>
            <SubTitle>To access Crossing Minds Admin Dashboard</SubTitle>
          </TitleContainer>

          <InputContainer>
            <LoginForm />
          </InputContainer>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default Login;
