import { useState } from 'react';

import { TextField, Icon } from '@shopify/polaris';
import { ViewMinor, HideMinor } from '@shopify/polaris-icons';

import { Container, Clickable, EyeIcon } from './PasswordTextField.styled';

const PasswordTextField = ({
  value,
  setValue,
  errorMessage,
  label = 'Password',
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const type = showPassword ? 'text' : 'password';
  const eye = showPassword ? (
    <Icon source={HideMinor} color="base" />
  ) : (
    <Icon source={ViewMinor} color="base" />
  );

  return (
    <Container>
      <Clickable onClick={() => setShowPassword(!showPassword)}>
        <EyeIcon>{eye}</EyeIcon>
      </Clickable>

      <TextField
        value={value}
        onChange={setValue}
        label={label}
        type={type}
        autoComplete="password"
        error={errorMessage}
      />
    </Container>
  );
};

export default PasswordTextField;
