import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  setDbIdReq,
  createServiceAccountReq,
  getServiceAccountsReq,
} from './accountSettingsAPI';
import { getShopDataThunk } from '../shop/shopSlice';

export const SETTINGS_VARIANTS = {
  FRONTEND: 'frontend',
  BACKEND: 'backend',
  DB: 'db',
};

const initialState = {
  db: {
    dbId: '',
    isEditing: false,
    isLoading: false,
  },
  frontend: {
    name: '',
    isEditing: false,
    isLoading: false,
  },
  backend: {
    name: '',
    isEditing: false,
    isLoading: false,
  },
};

export const setDbIdThunk = createAsyncThunk(
  'accountSettings/setDbIdThunk',
  async ({ shopKey, dbId }, { dispatch }) => {
    dispatch(setIsLoading({ variant: 'db', bool: true }));
    const { data } = await setDbIdReq({ shopKey, dbId }).finally(() => {
      dispatch(setIsLoading({ variant: 'db', bool: false }));
    });

    return {
      ...data,
      dbId,
    };

    // The value we return becomes the `fulfilled` action payload
  }
);

export const setAccountThunk = createAsyncThunk(
  'accountSettings/setAccountThunk',
  async ({ shopKey, name, password, accountType }, { dispatch }) => {
    dispatch(setIsLoading({ variant: accountType, bool: true }));

    const { data } = await createServiceAccountReq({
      shopKey,
      name,
      password,
      accountType,
    }).finally(() => {
      dispatch(setIsLoading({ variant: accountType, bool: false }));
    });

    dispatch(getAccountsThunk({ shopKey }));

    return {
      data,
      accountType,
    };
  }
);

export const getAccountsThunk = createAsyncThunk(
  'accountSettings/getAccountsThunk',
  async ({ shopKey }) => {
    const { data } = await getServiceAccountsReq(shopKey);

    return {
      data,
    };
  }
);

export const accountSettingsSlice = createSlice({
  name: 'accountSettings',
  initialState,
  reducers: {
    setIsEditing: (state, action) => {
      const { variant, bool } = action.payload;
      state[variant].isEditing = bool;
    },
    setIsLoading: (state, action) => {
      const { variant, bool } = action.payload;
      state[variant].isLoading = bool;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getShopDataThunk.fulfilled, (state, { payload }) => {
        state.status = 'idle';
      })

      .addCase(setDbIdThunk.fulfilled, (state, action) => {
        state.db.isEditing = false;
      })

      .addCase(getAccountsThunk.fulfilled, (state, { payload }) => {
        const { data } = payload;

        const frontendAccount = data?.find(
          (acc) => acc.accountType === SETTINGS_VARIANTS.FRONTEND
        );
        if (frontendAccount) {
          state[SETTINGS_VARIANTS.FRONTEND].name = frontendAccount.accountName;
        } else {
          state[SETTINGS_VARIANTS.FRONTEND].isEditing = true;
        }

        const backendAccount = data?.find(
          (acc) => acc.accountType === SETTINGS_VARIANTS.BACKEND
        );

        if (backendAccount) {
          state[SETTINGS_VARIANTS.BACKEND].name = backendAccount.accountName;
        } else {
          state[SETTINGS_VARIANTS.BACKEND].isEditing = true;
        }
      })
      .addCase(setAccountThunk.fulfilled, (state, { payload }) => {
        const { accountType } = payload;
        state[accountType].isEditing = false;
        state[accountType].isLoading = false;
      });
  },
});

export const { setIsEditing, setIsLoading } = accountSettingsSlice.actions;

export default accountSettingsSlice.reducer;
