import axios from '../../app/axiosConfig';

export const setDbIdReq = async ({ shopKey, dbId }) => {
  return axios.post(`/shops/${shopKey}/settings/database`, {
    dbId,
  });
};

export const createServiceAccountReq = async ({
  shopKey,
  name,
  password,
  accountType,
}) => {
  return axios.post(`/shops/${shopKey}/settings/service-accounts`, {
    name,
    password,
    accountType,
  });
};

export const getServiceAccountsReq = async (shopKey) => {
  return axios.get(`/shops/${shopKey}/settings/service-accounts`);
};
